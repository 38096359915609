
import React, { useState } from "react";

import { useLocation, Link } from 'react-router-dom';
import ReceipeForm from "../../../components/Form/Master/ReceipeForm";
import * as XLSX from 'xlsx';

export default function EditMenuitem() {

    const [ingredientList, setIngredientList] = useState([]);
    const location = useLocation();
    const detail = location.state;


    const handleExport = () => {
        const itemName = detail[0]?.item_name;
        const engName = detail[0]?.eng_name;

        const itemDisplayName = itemName || engName ? `${itemName ?? ''}${engName ? ` (${engName})` : ''}`.trim() : '-';

        const header = ["Id", "Ingredients", "Qty/100kg", "Unit"];
        const itemNameArray = ["Item Name", itemDisplayName];
        
        // Prepare data for export
        const data = [
            itemNameArray, // Item name row
            [], // Empty row for spacing
            header, // Header row
            ...detail[0].rows.map((item, index) => {
                const ingrd = ingredientList.find((v) => v.id == item.ingredient_id);
                return [
                    index + 1,
                    `${ingrd?.ingredient || ingrd?.eng_name ? `${ingrd?.ingredient ?? ''}${ingrd?.eng_name ? ` (${ingrd.eng_name})` : ''}`.trim() : '-'}`,
                    item.qty,
                    ingrd?.unit?.name ?? "-"
                ];
            })
        ];

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Recipe');

        // Generate a file and trigger download
        const fileName = `${itemName}_${engName}_Recipe.xlsx`.replace(/\s+/g, '_');
        XLSX.writeFile(workbook, fileName);
    }
    const handelIngredientList = (list) => {
        setIngredientList(list);
    }

  return (
    <div className="p-4">
        <div className="flex justify-between items-center py-4 px-6">
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Receipe Edit</h1>
            <div>
                <button onClick={handleExport} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Export</button>
                <Link to="/ingredients/add">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Ingredient</button>
                </Link>
                <Link to="/receipe/list">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                </Link>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
           <ReceipeForm detail={detail} handelIngredientList={handelIngredientList} />
        </div>
    </div>
  )
}
