import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {baseUrl } from "../../comman/config";
import { useLocation } from 'react-router-dom';

const DateSessionFilter = ({ handleFilterChange }) => {
    const today = new Date().toISOString().split('T')[0];
    const [date, setDate] = useState(today);
    const [session, setSession] = useState("");
    const [method, setMethod] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const location = useLocation();
    const routeName = location.pathname
    useEffect(() => {
    const storedDate = sessionStorage.getItem('selectedDate');
    const storedSession = sessionStorage.getItem('selectedSession');

    const clearSessionAfterOneHour = () => {
        sessionStorage.removeItem('selectedDate');
        sessionStorage.removeItem('selectedSession');
      };
    if(storedDate && storedSession){
      const parsedSession = JSON.parse(storedSession);
      setDate(storedDate);
      setSession(parsedSession);
      handleFilterChange(storedDate, parsedSession);
    }

    setTimeout(clearSessionAfterOneHour, 3600 * 1000);
   
  }, []);
    useEffect(() => {
        getSessionList();
        getMethodList()
    }, []);

    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`,
            { headers: { 'Cache-Control': 'no-cache' } }
        );
        setSessionList(response.data.data);
    }

    const getMethodList = async () => {
        const response = await axios.get(
          `${baseUrl}/comman/list?table=method_master&condition=status=1`
        );
        setMethodList(response.data.data);
      };

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleSelectChange = (data, option) => {
        setSession(data);
    };

    const handleChangeMultiSelect = (selected, name) => {
        if(name === 'method'){
            setMethod(selected);
        }
    };

    const handleSubmit = () => {
        // Handle submit logic here
        // You can pass date and session values to the parent component using handleFilterChange
        sessionStorage.setItem('selectedDate', date);
    sessionStorage.setItem('selectedSession', JSON.stringify(session));
        handleFilterChange(date, session, method);
    };

    return (
        <div className="pb-4 flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center pb-4">
            <div className='m-5'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={handleDateChange}
                    value={date}
                />
            </div>
            <div className='m-5'>
                {/* <select
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={handleSessionChange}
                    value={session}
                >
                    <option value="">Select Session</option>
                    
                </select> */}
                <Select
                    classNamePrefix="select"
                    value={session}
                    name="session"
                    onChange={(selectedOption, name) => {
                        handleSelectChange(selectedOption, name);
                    }}
                    options={sessionList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            {(routeName == '/finished_good/list') && <div className='m-5'>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={method}
                    name="method"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'method')}
                    options={methodList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    placeholder="Method List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>}
            <div className='m-5'>
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export {DateSessionFilter};
