import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../comman/config";
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const today = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        date : today,
        session : '',
        dish_count : 0,
        pass_issued: 0,
    });
    const [validationErrors, setValidationErrors] = useState({
        date: false,
        session: false,
        dish_count: false
    });
    const [popupOpen , setPopupOpen] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const formRef = useRef(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        getSessionList();
    }, []);

    


    useEffect(() => {
        if(detail ){
            const session = detail[0].session;
            
            const sessionItem =  sessionList.find((item) => item.id == session);
            const sessionObject = sessionItem ? {value : sessionItem.id , label : sessionItem.name} : {value : '', label : ''};
            setFormData({
                date: detail[0].date || today,
                session: sessionObject || '',
                dish_count: detail[0].total_dish || 0,
                pass_issued: detail[0].pass_issued || 0,
                
            });
        }
    }, [detail, sessionList]);


    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        setSessionList(response.data.data);
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        
        const newValidationErrors = {
            date: formData.date === '',
            session: formData.session === '',
            dish_count: formData.dish_count === 0
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                console.log(formData);
                setPopupOpen(true);
                if(detail){
                    url = `dish_count/edit/${detail[0].id}`;
                }else{
                    url = "dish_count/add";
                }

                

                await axios.post(`${baseUrl}/${url}`, {
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/dish_count/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
       
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleSelectChange = (data, option) => {
        
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data,
               
            }));
       
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }


  return (
 
<form ref={formRef} className="space-y-12 w-full lg:w-64">
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
    <div className="sm:col-span-2">
      <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">Date</label>
      <input
        type="date"
        name="date"
        id="date"
        value={formData.date}
        onChange={handleInputChange}
        className={`block w-full rounded-md border ${validationErrors.date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />
      {validationErrors.date && <div className="text-red-500">Please enter a date.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="session" className="block text-sm font-medium leading-6 text-gray-900">Session</label>
      <Select
        classNamePrefix="select"
        value={formData.session}
        name="session"
        onChange={(selectedOption, name) => {
          handleSelectChange(selectedOption, name);
        }}
        options={sessionList.map(item => ({
          value: item.id,
          label: item.name
        }))}
        filterOption={(option, inputValue) => {
          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
        }}
      />
      {validationErrors.session && <div className="text-red-500">Please select a session.</div>}
    </div>
    
    <div className="sm:col-span-2">
      <label htmlFor="dish_count" className="block text-sm font-medium leading-6 text-gray-900">Dish Issued as per API</label>
      <input
        id="dish_count"
        name="dish_count"
        type="number"
        step="0.01"
        placeholder='Dish Count'
        value={formData.dish_count}
        onChange={handleInputChange}
        onWheel={(e) => e.target.blur()}
        className={`block w-full rounded-md border ${validationErrors.dish_count ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />
      {validationErrors.dish_count && <div className="text-red-500">Please enter the dish issued.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="pass_issued" className="block text-sm font-medium leading-6 text-gray-900">Coupons sold</label>
      <input
        id="pass_issued"
        name="pass_issued"
        type="number"
        step="0.01"
        placeholder='Pass issued'
        value={formData.pass_issued}
        onChange={handleInputChange}
        onWheel={(e) => e.target.blur()}
        className={`block w-full rounded-md border ${validationErrors.pass_issued ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />
      
    </div>
   
  </div>
  <div className="mt-6 flex items-center gap-x-6">
    <Link to="/dish_count/list">
      <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
    </Link>
    <button
      onClick={handleSubmit}
      type="submit"
      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Save
    </button>
  </div>
</form>



            )
        }