import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        event_name : '',
        start_date : '',
        end_date : '',
        maharaj_name: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        event_name: false,
        start_date: false,
        end_date: false,
        maharaj_name: false

    });
    const [popupOpen , setPopupOpen] = useState(false);
    const [maharajList, setMaharajList] = useState([])
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    
    useEffect(()=>{
        getMaharajList()
    },[])

    useEffect(() => {
        if(detail){
            setFormData({
                event_name: detail[0].event_name || '',
                start_date: detail[0].start_date || '',
                end_date: detail[0].end_date || '',
                maharaj_name : detail[0]?.maharaj?.id || ''
            });
        }
        // console.log(detail[0]);
    }, [detail]);

    const getMaharajList = async () => {
        const response = await axios.get(`${baseUrl}/maharaj/list`);
        const filteredList = response.data.data.filter((maharaj)=> maharaj.status === 1)
        setMaharajList(filteredList);
    }   

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newValidationErrors = {
            event_name: formData.event_name === '',
            start_date: formData.start_date === '',
            end_date : formData.end_date === '',
            maharaj_name : formData.maharaj_name === ''
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `event/edit/${detail[0].id}`;
                }else{
                    url = "event/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/event_master/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap ">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Event Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="event_name"
                                      id="event_name"
                                      placeholder='Event Name'
                                      value={formData.event_name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.event_name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.event_name && <div className="text-red-500">Please enter a event name.</div>}
                            </div>
                            
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                                <div className="mt-2">
                                <input
                                      id="start_date"
                                      name="start_date"
                                      type="date"
                                      value={formData.start_date}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.start_date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.start_date && <div className="text-red-500">Please enter Start Date.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                                <div className="mt-2">
                                    <input
                                      id="end_date"
                                      name="end_date"
                                      type="date"
                                      value={formData.end_date}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.end_date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.end_date && <div className="text-red-500">Please enter end date.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="maharaj_name" className="block text-sm font-medium leading-6 text-gray-900">Maharaj Name</label>
                                <div className="mt-2">
                                    <select 
                                        id="maharaj_name"
                                        name="maharaj_name"
                                        type="text"
                                        value={formData.maharaj_name}
                                        onChange={handleInputChange}
                                        className={`block w-full rounded-md border ${validationErrors.maharaj_name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                            <option value="">Select Maharaj</option>
                                            {maharajList.map((item) => (
                                                <option value={item.id} key={item.id}>{item.maharaj_name}</option>
                                            ))};
                                        </select>
                                    
                                    </div>
                                {validationErrors.maharaj_name && <div className="text-red-500">Please enter a maharaj name.</div>}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/event_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }