const getBaseUrl = () => {
    const currentDomain = window.location.hostname;
    if(currentDomain === 'localhost'){
        return 'http://localhost:5000'; 
    }else{
        return `https://${currentDomain}/api`;
    }
};

const baseUrl = getBaseUrl();
const limit = 20;
const batchSize = 5;
const rotiBatchSize = 15;

const timeSlotList = [
    {value : "4 to 5am" , label: "4 to 5am"},
    {value : "5 to 6am" , label: "5 to 6am"},
    {value : "6 to 7am" , label: "6 to 7am"},
    {value : "7 to 8am" , label: "7 to 8am"},
    {value : "8 to 9am" , label: "8 to 9am"},
    {value : "9 to 10am" , label: "9 to 10am"},
    {value : "10 to 11am" , label: "10 to 11am"},
    {value : "11 to 12am" , label: "11 to 12am"},
    {value : "12 to 1am" , label: "12 to 1am"},
    {value : "1 to 2pm" , label: "1 to 2pm"},
    {value : "2 to 3pm" , label: "2 to 3pm"},
    {value : "3 to 4pm" , label: "3 to 4pm"},
    {value : "4 to 5pm" , label: "4 to 5pm"},
    {value : "5 to 6pm" , label: "5 to 6pm"},
    {value : "6 to 7pm" , label: "6 to 7pm"},
    {value : "7 to 8pm" , label: "7 to 8pm"},
    {value : "8 to 9pm" , label: "8 to 9pm"},
];

const timeList = [
    { value: "1 AM", label: "1 AM" },
    { value: "1:30 AM", label: "1:30 AM" },
    { value: "2 AM", label: "2 AM" },
    { value: "2:30 AM", label: "2:30 AM" },
    { value: "3 AM", label: "3 AM" },
    { value: "3:30 AM", label: "3:30 AM" },
    { value: "4 AM", label: "4 AM" },
    { value: "4:30 AM", label: "4:30 AM" },
    { value: "5 AM", label: "5 AM" },
    { value: "5:30 AM", label: "5:30 AM" },
    { value: "6 AM", label: "6 AM" },
    { value: "6:30 AM", label: "6:30 AM" },
    { value: "7 AM", label: "7 AM" },
    { value: "7:30 AM", label: "7:30 AM" },
    { value: "8 AM", label: "8 AM" },
    { value: "8:30 AM", label: "8:30 AM" },
    { value: "9 AM", label: "9 AM" },
    { value: "9:30 AM", label: "9:30 AM" },
    { value: "10 AM", label: "10 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "11 AM", label: "11 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "12 AM", label: "12 AM" },
    { value: "12:30 AM", label: "12:30 AM" },
    { value: "1 PM", label: "1 PM" },
    { value: "1:30 PM", label: "1:30 PM" },
    { value: "2 PM", label: "2 PM" },
    { value: "2:30 PM", label: "2:30 PM" },
    { value: "3 PM", label: "3 PM" },
    { value: "3:30 PM", label: "3:30 PM" },
    { value: "4 PM", label: "4 PM" },
    { value: "4:30 PM", label: "4:30 PM" },
    { value: "5 PM", label: "5 PM" },
    { value: "5:30 PM", label: "5:30 PM" },
    { value: "6 PM", label: "6 PM" },
    { value: "6:30 PM", label: "6:30 PM" },
    { value: "7 PM", label: "7 PM" },
    { value: "7:30 PM", label: "7:30 PM" },
    { value: "8 PM", label: "8 PM" },
    { value: "8:30 PM", label: "8:30 PM" },
    { value: "9 PM", label: "9 PM" },
    { value: "9:30 PM", label: "9:30 PM" },
    { value: "10 PM", label: "10 PM" },
    { value: "10:30 PM", label: "10:30 PM" },
    { value: "11 PM", label: "11 PM" },
    { value: "11:30 PM", label: "11:30 PM" },
    { value: "12 PM", label: "12 PM" },
    { value: "12:30 PM", label: "12:30 PM" }
];


const rotliTypeList = [
    {id: 1, label: "Machine"},
    {id: 2, label: "Swaminarayan"},
    {id: 3, label: "Manual"}
];

const noOfGullaList = [
    {id: 1, label: "1"},
    {id: 2, label: "2"},
    {id: 3, label: "3"},
    {id: 4, label: "4"},
    {id: 5, label: "5"},
    {id: 6, label: "6"}
];


const generateRequisitionNumber = (index,activeIndex,date, session, mealList,vesselList, vessel) => {
    // console.log(vessel);
    const today = new Date(date);
    // console.log(today)
    const day = today.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Get month (Note: January is 0, so add 1) and pad with leading zero if needed
    const year = today.getFullYear().toString().slice(-2);
    // const dayOfMonth = today.getDate();
    const ddmmyy = `${day}${month}${year}`;
    const sessionName = session.label.charAt(0);
    const item = mealList.find((item, index) => index == activeIndex);
    const itemName = item.eng_name.replace(/\s/g, "-");
    const vesselName = vesselList.find((item) => item.id == vessel)?.name ?? "-";
    const requisition_no = ddmmyy + "/" + sessionName + "/" + itemName + "/" + vesselName + "-" + index;
    return requisition_no;
    
}

const getDateWithDay = (date) => {
    const dateObj = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('en', { day: '2-digit', month: 'short', year: 'numeric', weekday: 'short' }).format(dateObj);
    return formattedDate;
}

const reportTypeList = [
    {id: 1, label:"Master"},
    {id: 2, label:"Working"},
    {id: 3, label:"Production"},
];

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
};

const requirementStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Requirement"},
    {value: 2, label:"Submitted for Order"},
];
const orderStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Order"},
    {value: 3, label:"Order Place"},
];
const purchaseStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Purchase"},
    {value: 3, label:"Purchased"},
];
const receiptStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Receipt"},
    {value: 3, label:"Received"},
];

const issueStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Issue"},
    {value: 2, label:"Partly Issue"},
    {value: 3, label:"Issued"},
];

const returnReceiptStatusList = [
    {value: 0, label:"All"},
    {value: 1, label:"Pending Receipt"},
    {value: 2, label:"Partly Receipt"},
    {value: 3, label:"Receipt"},
]

const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const appropriateItemSeason = [
    {value:0,label:"Winter season (Nov, Dec, Jan, Feb)"},
	{value:1,label:"Summer season (Mar, April, May, June)"},
	{value:2,label:"Rainy season (July, Aug, Sept, Oct)"},
	{value:3,label:"All season"}
]

const mahatmaRatings = [
    {value:4, label:"Excellent"}, 
    {value:3, label:"Good"}, 
    {value:2, label:"Average"}, 
    {value:1, label:"Poor"}, 
    {value:0, label:"Very Poor"}
]
export {baseUrl, limit, batchSize,rotiBatchSize, timeSlotList,timeList, rotliTypeList, noOfGullaList,reportTypeList,requirementStatusList,issueStatusList,orderStatusList,purchaseStatusList,receiptStatusList,returnReceiptStatusList, generateRequisitionNumber, getDateWithDay, formatDate, addDays, appropriateItemSeason, mahatmaRatings};